<template>
  <section class="container">
    <Pbanner :pbanner="pbanner"/>
    
    <div class="search__fix">
      <div class="search__wrap inner">
        <div class="social__search">
            <i class="iconfont">&#xe610;</i>
            <input type="text" v-model="innerkeyword" @keydown.enter="slidelist" class="text" placeholder="搜索关键词"/>
            <span class="search__btns" @click="slidelist">搜索</span>
        </div>
        <div class="all__posite">全部结果（共 <i>{{total}}</i> 个）</div>
        <ul class="search__list">
          <li v-for="(item, index) in searchData.list" :key="index">
            <router-link :to="'/partyact/'+item.cid">
              <strong>{{item.title}}</strong>
              <time>{{item.create_time}}</time>
            </router-link>
          </li>
        </ul>
        <div class="user-pagination" >
            <el-button type="button" @click="FirstPage" class="my-btn">首页</el-button>
            <el-pagination ref="pagination"
                background prev-text="上一页" next-text="下一页"
                layout="prev, pager, next, slot"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :total="total"
                :page-size="pagesize"
              >
            </el-pagination>
            <el-button type="button" @click="LastPage" class="my-btn" style="margin: 0px 5px">尾页</el-button>
            </div>
      </div>
    </div>
    <Footer/>
  </section>
</template>

<script>
import { useMyStore } from '../store';
import Footer from '../components/Footer';
import Pbanner from '../components/Pbanner';
export default {
  data() {
    return {
      pbanner:require('../assets/img/search.jpg'),
      innerkeyword:'',
      queryInfo: {
          page: 1,
          pagesize: 10,
          keywords: '',
      },
      total: 0,
      pagesize: 4,
      searchData: {}
    };
  },
  components: {
    Pbanner,
    Footer,
  },
  methods: {
    async getSearchData(){
      var qs = require('qs');
      const {data: res} = await this.$http.post("searchList",qs.stringify(this.queryInfo));
      if(res.status == 1){
        this.searchData = res.data
        this.total = Number(res.data.pageinfo.count);
      }
    },
    slidelist() {
      this.queryInfo.keywords = this.innerkeyword;
      this.getSearchData();
    },
    handleCurrentChange(newPage){
      this.queryInfo.page = newPage;
      this.getSearchData();
    },
    FirstPage(){
      this.$refs.pagination.handleCurrentChange(1);
      this.$emit('handleCurrentChange', 1);
    },
    LastPage(){
      let font = this.$refs.pagination
      let cpage = Math.ceil(font.total / font.pageSize);
      font.handleCurrentChange(cpage);
    }
  },
  created() {},
  mounted() {
    this.queryInfo.keywords = this.$route.params.keywords
    this.getSearchData()
  }
};
</script>
<style lang="less" scoped>

    
</style>